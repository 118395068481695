.heading {
  font-weight: var(--font-weight-semibold);
  color: var(--semantic-color-text-primary);
  padding: 0;
  line-height: 1.25em;
}

.extra-small {
  font-size: var(--font-size-title-extra-small);
}

.small {
  font-size: var(--font-size-title-small);
}

.medium {
  font-size: var(--font-size-title-medium);
}

.medium-large {
  font-size: var(--font-size-title-medium-large);
}

.large {
  font-size: var(--font-size-title-large);
}