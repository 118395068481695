.container {
  --font-size: 24px;
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
  width: 133px;
  height: 25px;
}

.container:focus-visible {
  outline: var(--semantic-color-interaction-outline);
}
