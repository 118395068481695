.content {
  position: relative;
  height: max-content;
  width: 100%;
  padding: 0 var(--padding-horizontal);
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  background: var(--semantic-color-background-primary);
}
