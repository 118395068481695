.header {
    margin-bottom: var(--block-l);
}

.heading {
    margin-bottom: var(--block-m);
}

.content {
    flex-direction: column;
    gap: 0;
    margin-bottom: var(--block-xl);
}