.link {
  position: relative;
  color: var(--semantic-color-background-primary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-body);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  outline: none;
  transition: filter 0.1s ease-in-out;
  width: max-content;
  height: max-content;
}

.link:before {
  content: "";
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--semantic-color-interaction-primary);
  bottom: -3px;
  left: 0;
  transition: opacity 100ms;
  opacity: 0;
}

.link.active:before,
.link:hover:before {
  opacity: 1;
}

.link:focus-visible {
    outline: var(--semantic-color-interaction-outline);
}