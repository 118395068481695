.link {
    color: var(--semantic-color-interaction-primary);
    text-decoration: underline;
}

.link:not(.disabled):hover, .link:not(.disabled):focus-visible {
    text-decoration: none;
}

.link:visited {
    color: var(--color-brand-dark-red);
}

.link.disabled {
    color: var(--semantic-color-disabled);
    text-decoration: none;
}