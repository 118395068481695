.button {
    background: var(--color-brand-teal);
    color: var(--semantic-color-text-primary-inverse);
    outline: none;
    border: none;
    padding: var(--block-xs) var(--block-s);
    border-radius: 10px;
    font-size: var(--font-size-body);
    font-weight: var(--font-weight-semibold);
    font-family: inherit;
    cursor: pointer;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.2s ease;
}

.button svg {
    width: 1.5em;
    height: 1.5em;
    transition: transform 0.2s ease;
}

.button:hover,
.button:focus-visible {
    transform: translateY(-2px);
    color: var(--color-brand-dark-teal);
    background-color: var(--color-brand-light-teal);
    box-shadow: 0 0 0 3px var(--color-brand-teal);
}

.button:hover svg,
.button:focus-visible svg {
    transform: translateX(0.25em);
    fill: var(--color-brand-dark-teal);
}

.button:active {
    color: var(--semantic-color-text-primary-inverse);
    background: black;
    transform: translateY(2px);
    box-shadow: none;
}

.button:active svg {
    fill: var(--semantic-color-text-primary-inverse);
}