.button {
  --translate-x: 25px;
  display: none;
  background: none;
  border: none;
  padding: 0.25rem 0.5rem;
  margin: 0 -0.75rem 0 0;
  position: absolute;
  top: calc(var(--header-height) / 2);
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
}

.button > svg {
  fill: var(--semantic-color-text-primary);
}

.button.active > svg {
  fill: var(--color-brand-grey);
}

@media only screen and (max-width: 1000px) {
  .button {
    display: flex;
    align-items: center;
  }
}
